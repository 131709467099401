@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DIN-Condensed';
  src: url('./assets/font/DIN-Condensed-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Influence';
  src: url('./assets/font/D-DIN-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600&family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@400;500;700&family=Open+Sans:wght@400;700&family=Urbanist:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: inter, DIN-Condensed, sans-serif, Cairo, Montserrat !important;
  font-variant: normal !important;
  color: white;
  background-color: #202020;
}
.input-wrapper {
  @apply w-full flex flex-col gap-1.5;
}
.tw-label {
  @apply text-primary-orange text-[16px] font-bold;
}
.tw-input {
  @apply block w-full border-0 border-b border-white/50 bg-primary-dark-grey focus:border-white focus:ring-0 sm:text-sm;
}
.tw-input-secondary {
  @apply block w-full border-2 rounded border-white/50 bg-primary-dark-grey focus:border-white focus:ring-0 sm:text-sm disabled:text-primary-light-grey py-3;
}
.tw-input-primary {
  @apply block w-full border-2 rounded border-primary-light-grey placeholder:!text-primary-light-grey bg-primary-medium-grey focus:border-primary-orange focus:ring-0 text-[16px] pl-3 disabled:text-primary-light-grey py-3 focus:outline-none;
}
.tw-input-orange-dark {
  @apply bg-primary-dark-grey placeholder:bg-primary-dark-grey block text-left rounded-[4px] py-3 px-5 border border-solid border-primary-orange placeholder-primary-light-grey inline-flex items-center justify-center placeholder:text-center text-white disabled:opacity-[.35] appearance-none focus:outline-none focus:ring-transparent sm:text-lg font-bold h-13;
}
.tw-input-orange-dark-secondary {
  @apply font-inter bg-primary-dark-grey placeholder:bg-primary-dark-grey rounded-[4px] inline-flex items-center justify-center px-10 py-3 border  border-solid border-primary-orange text-base font-bold rounded shadow-sm text-white disabled:bg-primary-light-grey disabled:cursor-not-allowed focus:outline-none capitalize h-13;
}
.tw-input-blue-dark {
  @apply bg-primary-dark-grey  placeholder:bg-primary-dark-grey block text-left rounded-sm py-2 px-5 border border-primary-blue focus:border-primary-blue/80 placeholder-primary-light-grey placeholder:text-left placeholder:text-xs text-white disabled:opacity-[.35] appearance-none focus:outline-none focus:ring-transparent sm:text-base font-medium;
}
.tw-input-mid-dark {
  @apply bg-primary-medium-grey placeholder:bg-primary-medium-grey block text-left py-3 px-0 border-0 border-b-2 border-b-white/60 placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none focus:ring-transparent focus:border-white sm:text-lg font-medium w-full;
}
.tw-button-light-blue {
  @apply font-inter bg-primary-blue inline-flex items-center justify-center px-10 py-3 border border-white active:!bg-white active:!border-primary-blue active:!text-primary-blue text-base font-bold rounded shadow-sm text-white disabled:bg-primary-light-grey disabled:cursor-not-allowed focus:outline-none capitalize h-13;
}
.tw-button-secondary {
  @apply bg-transparent inline-flex items-center justify-center px-10 sm:px-14 py-3 text-xl font-bold rounded-[4px] shadow-sm text-white border border-primary-orange hover:bg-primary-medium-grey disabled:bg-opacity-90 disabled:text-white/[0.65] disabled:cursor-not-allowed focus:outline-none h-13;
}
.tw-button-blue-outline {
  @apply bg-primary-dark-grey inline-flex items-center justify-center px-7 py-3 text-base font-bold rounded-[4px] shadow-sm text-white border-2 border-primary-blue hover:bg-primary-medium-grey disabled:bg-opacity-90 disabled:text-white/[0.65] disabled:cursor-not-allowed focus:outline-none capitalize h-13;
}
.primaryBorder {
  @apply border-2 border-primary-orange;
}
.secondaryBorder {
  @apply border-2 border-primary-blue rounded-[4px];
}
.primaryTextArea {
  @apply bg-primary-medium-grey placeholder:bg-primary-medium-grey block text-left py-3 px-3 border-2 border-white/60 rounded-md placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none focus:ring-transparent focus:border-white sm:text-sm font-medium w-full min-h-[133px];
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  color: white !important;
  box-shadow: 0 0 0 30px #171717 inset !important;
  -webkit-box-shadow: 0 0 0 30px #171717 inset !important;
  -webkit-text-fill-color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ellipsis {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 430px) {
  .emailStyle {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 420px) {
  .break-Line {
    display: block;
  }
}

input[type='text'] {
  ring-inset: initial;
  ring-offset-color: transparent;
  ring-color: transparent;
  ring-offset-width: 0px;
  ring-offset-shadow: none;
  ring-width: 0px;
  ring-shadow: none;
  box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
