.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.rdrDayDisabled {
  background-color: transparent !important;
}
.rdrDayDisabled > span > span {
  color: #6f7577 !important;
}
.rdrMonth {
  width: 100% !important;
}
.rdrNextPrevButton,
.rdrNextButton {
  display: none !important;
}
.rdrDayNumber::after {
  border: none !important;
}
.rdrDayNumber {
  top: 0;
  height: 100%;
  padding: 4px;
}
.rdrDayNumber > span {
  color: #fff !important;
}
.rdrWeekDay {
  font-weight: 700 !important;
  color: #fff !important;
}
.rdrDays {
}
.rdrCalendarWrapper {
}
.calendarElement {
}
.rdrDateDisplayWrapper {
}
.rdrDayPassive {
}
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #e66752 !important;
}
.rdrDayPassive .rdrDayNumber span {
  color: #6f7577 !important;
}
.rdrDayToday .rdrDayNumber span:after {
  background-color: #e66752 !important;
}
.rdrMonthAndYearPickers {
}
.rdrMonthAndYearPickers select {
  color: white !important;
  background: #171717 !important;
}
.rdrMonthAndYearPickers select:focus {
  box-shadow: 0px 0px 0px 1px #6f7577;
}

/* phoneUnputStyle */
.flag-dropdown {
  background-color: #202020 !important;
}
.selected-flag:hover {
  background-color: #202020 !important;
}
.country-list {
  background-color: #202020 !important;
}
.react-tel-input {
}
.open {
  background-color: #202020 !important;
}
.country:hover {
  background-color: #171717 !important;
}
.highlight {
  color: white;
  font-weight: 800;
  background-color: #202020 !important;
}
.dial-code {
  color: #6f7577 !important;
}
